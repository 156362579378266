import {ApplicationRef, DoBootstrap, NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import {MatIconModule, MatIconRegistry} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {NgxPermissionsModule} from 'ngx-permissions';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {HttpErrorInterceptor} from '@core/interceptor/http-error-intercepter';
import {KeycloakConfig} from 'keycloak-js';
import {MatSnackBarModule} from '@angular/material/snack-bar';

const keycloakService = new KeycloakService();

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline'
};

@NgModule({ declarations: [
        AppComponent,
    ], imports: [BrowserModule,
        KeycloakAngularModule,
        NgxPermissionsModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatListModule,
        MatIconModule,
        MatMenuModule,
        MatButtonModule,
        MatSnackBarModule], providers: [
        { provide: KeycloakService, useValue: keycloakService },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule implements DoBootstrap {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/mdi.svg')); // Or whatever path you placed mdi.svg at
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    //FIXME : Weghalen
    const realm = window.location.hostname === 'kampala.100weeks.org' ? '100WEEKS-Test' : environment.kc_realm;

    const config: KeycloakConfig = {
      realm,
      url: environment.kc_url,
      clientId: environment.kc_client_id
    };

    keycloakService
      .init({
        config,
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false
        }
      })
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');

        appRef.bootstrap(AppComponent);
      })
      .catch(error => console.error('[ngDoBootstrap] init Keycloak failed', error));
  }
}
