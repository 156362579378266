import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService} from '@core/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private notificationService: NotificationService
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage = '';
          let lastErrorMessage = 'Unknown error';
          if (error && error.error && error.error.errors && error.error.errors.length > 0 && error.error.errors[0].message) {
            lastErrorMessage = error.error.errors[0].message;
          }

          if ((error.status === 400 || error.status === 404 || error.status === 500) && lastErrorMessage !== 'Unknown error') {
            errorMessage = `${lastErrorMessage}`;
          } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${lastErrorMessage}`;
          }

          this.notificationService.error(errorMessage, 'An error occurred', { duration: 0, horizontalPosition: 'end', verticalPosition: 'top'});
          return throwError(() => {
            const err = new Error(errorMessage);
            err.name = error.statusText;
            return err;
          });
        })
      );
  }
}
