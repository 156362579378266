import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BaseApi} from '@core/base/base-api';
import {User} from '@shared/user/user';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentUser} from '@shared/user/current-user';
import {Role} from '@shared/user/role';
import {SearchFieldGroup} from '@core/base/objects/search/search-field-group';
import {SearchOperator} from '@core/base/objects/search/search-operator.enum';
import {SearchField} from '@core/base/objects/search/search-field';
import {SearchRequest} from '@core/base/objects/search/search-request';
import {OrganisationProject} from '@shared/organisation/project/organisation-project';
import {Group} from '@shared/user/group';
import {Permissions} from '@core/constants/permissions';

@Injectable({
  providedIn: 'root'
})
export class UserApi extends BaseApi<User> {
  constructor(httpClient: HttpClient) {
    super(httpClient, 'users');
  }

  create(): User {
    return new User();
  }

  public findByUuid(uuid: string): Observable<User> {
    return this.httpClient.get<User>(`${this.endpoint}/${uuid}`).pipe(map(value => {
      return Object.assign(this.create(), value);
    }));
  }

  public update(model: User): Observable<boolean> {
    return this.httpClient.put<boolean>(`${this.endpoint}/${model.uuid}`, model);
  }

  getCurrentUser(): Observable<CurrentUser> {
    return this.httpClient.get<CurrentUser>(`${this.endpoint}/me`);
  }

  findByCountry(countryUuid: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.endpoint}/by-country/${countryUuid}`);
  }

  findByRole(roleName: string): Observable<User[]> {
    return this.httpClient.get<User[]>(`${this.endpoint}/by-role/${roleName}`);
  }

  roles(userUuid?: string): Observable<Role[]> {
    if (userUuid) {
      return this.httpClient.get<Role[]>(`${this.endpoint}/${userUuid}/roles/v2`);
    } else {
      return this.httpClient.get<Role[]>(`${this.endpoint}/roles/v2`);
    }
  }

  groups(userUuid?: string): Observable<Group[]> {
    if (userUuid) {
      return this.httpClient.get<Group[]>(`${this.endpoint}/${userUuid}/groups`);
    } else {
      return this.httpClient.get<Group[]>(`${this.endpoint}/groups`);
    }
  }

  setGroup(model: User, groupId: string) {
    return this.httpClient.put(`${this.endpoint}/${model.uuid}/groups/${groupId}`, {}, { observe: 'response' }).pipe(
      map((response) => {
        if (response.status === 202) {
          return true;
        } else {
          console.log('setGroup failed - Received status: ' + response.status);
          return false;
        }
      })
    );
  }

  removeGroup(model: User, groupId: string) {
    return this.httpClient.delete(`${this.endpoint}/${model.uuid}/groups/${groupId}`, { observe: 'response' }).pipe(
      map((response) => {
        if (response.status === 202) {
          return true;
        } else {
          console.log('removeGroup failed - Received status: ' + response.status);
          return false;
        }
      })
    );
  }

  setRole(model: User, roleId: string) {
    return this.httpClient.put(`${this.endpoint}/${model.uuid}/roles/${roleId}`, {}, {observe: 'response'}).pipe(
      map((response) => {
        if (response.status === 202) {
          return true;
        } else {
          console.log('setRole failed - Received status: ' + response.status);
          return false;
        }
      })
    );
  }

  addCountry(userUuid: string, countryUuid: string): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/${userUuid}/countries/${countryUuid}`, {});
  }

  removeCountry(userUuid: string, countryUuid: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.endpoint}/${userUuid}/countries/${countryUuid}`);
  }

  setHeadquarters(userUuid: string, headquarters: boolean): Observable<void> {
    return this.httpClient.put<void>(`${this.endpoint}/${userUuid}/headquarters`, {headquarters});
  }

  setPassword(model: User, password: string, temporary: boolean): Observable<boolean> {
    return this.httpClient.put(`${this.endpoint}/${model.uuid}/password`, {password, temporary}, {observe: 'response'}).pipe(
      map((response) => {
        if (response.status === 202) {
          return true;
        } else {
          console.log('set password failed received status: ' + response.status);
          return false;
        }
      })
    );
  }

  enable(user: User): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/${user.uuid}/enable`, {});
  }

  disable(user: User): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/${user.uuid}/disable`, {});
  }

  findAppUsersForProject(project: OrganisationProject): Observable<User[]> {
    const searchFilter: SearchFieldGroup = {
      type: 'AND',
      fields: [
        { fieldName: 'applicationRole', operator: SearchOperator.CUSTOM, value: Permissions.APPLICATION_ONBOARDING } as SearchField,
        { fieldName: 'contact.defaultAddress.country.uuid', operator: SearchOperator.EQ, value: project.country?.uuid } as SearchField,
      ],
      groups: [],
    };

    const searchRequest: SearchRequest = {
      filter: searchFilter,
      pageIndex: 0,
      pageSize: 1000,
      sort: ['contact.name']
    };

    return this.findByRequest(searchRequest).pipe(
      map(result => result.data),
    );
  }
}
